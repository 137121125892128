import React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
import { useFormik } from "formik"
import InputMask from "react-input-mask"
import { useLocation } from "@reach/router"

const FormComponent = () => {
  const params = new URLSearchParams(useLocation().search.substring(1))
  const reference = params.get("ref")
  let ref = ""
  switch (reference) {
    case "menu":
      ref = "Navegou pelo menu"
      break
    case "curso":
      ref = `Interesse no curso: ${params.get("title")}`
      break
    case "cta":
      ref = "CTA Cursos Personalizados"
      break
    case "parceiro":
      ref = "CTA Seja nosso parceiro"
      break
    default:
      ref = "Nenhuma"
      break
  }

  const validate = values => {
    const errors = {}
    if (!values.name) {
      errors.name = "Campo obrigatório"
    }
    if (!values.subject) {
      errors.subject = "Campo obrigatório"
    }
    if (!values.phone) {
      errors.phone = "Campo obrigatório"
    }
    if (!values.message) {
      errors.message = "Campo obrigatório"
    }
    if (!values.email) {
      errors.email = "Campo obrigatório"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Digite um email válido"
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      subject: "",
      email: "",
      message: "",
      reference: ref,
    },
    validate,
    onSubmit: values => {
      fetch(`${process.env.API_URL || "http://localhost:1337"}/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          if (!response.ok) throw response
        })
        .then(result => alert("Mensagem enviada com sucesso!"))
        .catch(error => {
          alert("Houve um erro, tente novamente ou nos chame no Whatsapp!")
          formik.setSubmitting(false)
        })
    },
  })

  return (
    <Row className="contact">
      <Col className="form-holder">
        <Form onSubmit={formik.handleSubmit} className="contactform">
          <Form.Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errors position-fixed">
                    <span>{formik.errors.name}</span>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="phone">
                <InputMask
                  type="text"
                  placeholder="Telefone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  mask="(99) 9 9999-9999"
                  maskChar=" "
                  className="form-control"
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="errors position-fixed">
                    <span>{formik.errors.phone}</span>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Assunto"
                  name="subject"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="errors position-fixed">
                    <span>{formik.errors.subject}</span>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errors position-fixed">
                    <span>{formik.errors.email}</span>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder="Mensagem"
                  name="message"
                  rows="6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className="errors position-fixed">
                    <span>{formik.errors.message}</span>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group className="mt-2">
                <Button
                  variant="primary"
                  type="submit"
                  size="md"
                  disabled={
                    !(formik.isValid && formik.dirty) || formik.isSubmitting
                  }
                >
                  Enviar
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  )
}

export default FormComponent
