import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Banner from "../components/common/banner"
import Form from "../components/form"

import { GrMapLocation, GrPhone, GrMailOption } from "react-icons/gr"
import { FaWhatsapp } from "react-icons/fa"
import bgImage from "../images/contact/banner.png"

import "../assets/css/contact.css"

const ContactPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo title="Contato" />
        <Banner
          title="Contato"
          image={bgImage}
          links={[{ title: "Contato", path: "/contato" }]}
        />
        <Container className="half-padding-top padding-bottom">
          <Row>
            <Col>
              <h5>Interessado em discutir?</h5>
            </Col>
          </Row>
          <Row>
            <Col className="my-3">
              <Form />
            </Col>
            <Col
              className="contact-info text-center text-md-left"
              xs={12}
              md={6}
            >
              <div className="heading my-3 py-3 px-3 px-md-0">
                <Row>
                  <Col md={2} className="icon pb-3 pb-md-0">
                    <GrMapLocation />
                  </Col>
                  <Col>
                    <h4>Nossos endereços</h4>
                    <h5>Escola de Saúde CTEMER</h5>
                    <p>Santos Dumont, 329 - Bairro Centro - Pelotas</p>

                    <h5>Centro de Simulação em Atendimento Pré-Hospitalar</h5>
                    <p>Rua 02 número 1725 - Bairro Germani - Pelotas</p>
                  </Col>
                </Row>
              </div>
              <div className="heading my-3 py-3 px-3 px-md-0">
                <Row>
                  <Col md={2} className="icon pb-3 pb-md-0">
                    <GrPhone />
                  </Col>
                  <Col>
                    <h4>Nosso número de contato</h4>
                    <p>(53) 3228.4804</p>
                  </Col>
                </Row>
              </div>
              <div className="heading my-3 py-3 px-3 px-md-0">
                <Row>
                  <Col md={2} className="icon pb-3 pb-md-0">
                    <GrMailOption />
                  </Col>
                  <Col>
                    <h4>Nosso e-mail de contato</h4>
                    <p>secretariactemer@hotmail.com</p>
                  </Col>
                </Row>
              </div>
              <div className="heading my-3 py-3 px-3 px-md-0">
                <Row>
                  <Col md={2} className="icon pb-3 pb-md-0">
                    <FaWhatsapp />
                  </Col>
                  <Col>
                    <h4>Nosso whatsapp</h4>
                    <p>53 9.8155-1905</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  )
}

export default ContactPage
